import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { IntroProject, Intro, IntroImage } from '../../../components/Intro/Intro';
import { Section, Container, CSSGrid, Col } from '../../../components/Grid';
import { MainProject } from '../../../components/Card';
import { ButtonGhost } from '../../../components/Button';
import { designTokens } from '../../../components/Theme/designTokens';
import projects from '../../../data/projects';
import { IconBox, IconCard } from '../../../components/IconBox';
import { Box } from '../../../components/Box';
import { isLoggedIn } from "../../../utilities/services/auth";
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdFlag from 'react-ionicons/lib/MdFlag';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Chargebacks911" mdxType="SEO" />
    <IntroProject projectName="Chargebacks911" projectDescription="Helping online merchants optimize profitability" projectCaption="Chargebacks911® provides solutions for businesses dealing with falsely protested credit card charges by intelligently managing payment disputes" projectYear="2015 - 2019" projectImage="chargebacks911-preview" mdxType="IntroProject" />
    <Section section="Projects" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
            <h5>Overview</h5>
            <p>Chargebacks911® provides cutting-edge, highly-scalable enterprise solutions and specialized consulting for chargeback compliance, risk mitigation, and dispute management - <strong>which simply means providing solutions for businesses dealing with falsely protested credit card charges</strong>.</p>
            <IconCard cardTitle="My Role" cardText="Lead designer responsible for UI/UX of products, website design, marketing page/email templates, printed assets, etc." mdxType="IconCard">
            <MdPerson fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdPerson" />
            </IconCard>
            <IconCard cardTitle="Specifications" cardText="Help create consistent design standards across all of Chargebacks911's products and web/print marketing assets." mdxType="IconCard">
              <MdFlag fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdFlag" />
            </IconCard>
            <p>I was hired as the first designer on Chargebacks911's product team.</p>
            <p>Although I juggled between a role as the designer on the marketing team and the sole designer on our application development team (30+ developers), it came as a blessing in disguise - <strong>it allowed me to see exacly how we could reach companies who needed a profit-optimizing solution, and how we could develop a solution to do just that</strong>.</p>
            <p>Select one of the projects below to learn more:</p>
            <br />
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 2} media={{
            phone: 1,
            tablet: 1 / 2
          }} mdxType="Col">
          <MainProject title="Wizard & Merchant App" link={'/work/chargebacks911/merchant-app'} image="cb911-wizard-preview" mdxType="MainProject">
            <h5 style={{
                marginTop: '0'
              }}>
              Wizard & Merchant App
            </h5>
            <p>An onboarding experience and management application for clients</p>
            <ButtonGhost color={isLoggedIn() ? ({
                theme
              }) => theme.highlightedText : ({
                theme
              }) => theme.visited} mdxType="ButtonGhost">{isLoggedIn() ? 'View Project' : 'Private'}</ButtonGhost>
          </MainProject>
        </Col>
        <Col col={1 / 2} media={{
            phone: 1,
            tablet: 1 / 2
          }} mdxType="Col">
          <MainProject title="Internal Management Tool" link={'/work/chargebacks911/internal-app'} image="cb911-ers-preview" mdxType="MainProject">
            <h5 style={{
                marginTop: '0'
              }}>
              Internal Management Tool
            </h5>
            <p>A client management tool connected to large external datasets</p>
            <ButtonGhost color={isLoggedIn() ? ({
                theme
              }) => theme.highlightedText : ({
                theme
              }) => theme.visited} mdxType="ButtonGhost">{isLoggedIn() ? 'View Project' : 'Private'}</ButtonGhost>
          </MainProject>
        </Col>
        <Col col={1 / 2} media={{
            phone: 1,
            tablet: 1 / 2
          }} mdxType="Col">
          <MainProject title="Email Signature Generator" link={'/work/chargebacks911/email-signature'} image="cb911-email-preview" mdxType="MainProject">
            <h5 style={{
                marginTop: '0'
              }}>
              Email Signature Generator
            </h5>
            <p>Giving team members the ability to create their own email signatures</p>
            <ButtonGhost color={isLoggedIn() ? ({
                theme
              }) => theme.highlightedText : ({
                theme
              }) => theme.visited} mdxType="ButtonGhost">{isLoggedIn() ? 'View Project' : 'Private'}</ButtonGhost>
          </MainProject>
        </Col>
        <Col col={1 / 2} media={{
            phone: 1,
            tablet: 1 / 2
          }} mdxType="Col">
          <MainProject title="Marketing Design & Blog" link={'/work/chargebacks911/marketing'} image="cb911-marketing-preview" mdxType="MainProject">
            <h5 style={{
                marginTop: '0'
              }}>
              Marketing Design & Blog
            </h5>
            <p>Desinging a better experience through digital and print communications</p>
            <ButtonGhost color={isLoggedIn() ? ({
                theme
              }) => theme.highlightedText : ({
                theme
              }) => theme.visited} mdxType="ButtonGhost">{isLoggedIn() ? 'View Project' : 'Private'}</ButtonGhost>
          </MainProject>
        </Col>
      </CSSGrid>
  </Container>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      